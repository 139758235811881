import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import {
  Button,
  ArrowDownIcon,
  Box,
  useModal,
  SwapVertIcon,
  Flex,
  useMatchBreakpoints,
  PolygonRightIcon,
  CheckmarkIcon,
} from 'uikit'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import { RouteComponentProps } from 'react-router-dom'
import { useTranslation } from 'contexts/Localization'
import { TokenClass } from 'swap-sdk/entities/token'
import { useAccount, useNetwork } from 'wagmi'
import { Trade } from 'swap-sdk/entities/trade'
import { CurrencyAmount } from 'swap-sdk/entities/fractions/currencyAmount'
import { AppBody, AppHeader } from 'componentsV2/App'
import ConnectWalletButton from 'componentsV2/ConnectWalletButton/ConnectWalletButton'
import JSBI from 'jsbi'
import Page from 'componentsV2/layout/Page'
import { AutoColumn } from 'componentsV2/layout/Column'
import { AutoRow, RowBetween } from 'componentsV2/layout/Row'
import SwapWarningTokens from 'config/constants/swapWarningTokens'
import InfoCard from 'componentsV2/InfoCard/InfoCard'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import useTheme from 'hooks/useTheme'
import { MainTab } from 'componentsV2'
import { SWAP_TABS_MENU } from 'config/menus/tabs'
import WhiteCircleLoader from 'componentsV2/Loader/WhiteCircleLoader'
import useSwapAvailabilityCheck from 'hooks/useSwapAvailabilityCheck'
import SwapMigrationBanner from 'componentsV2/SwapMigrationBanner/SwapMigrationBanner'
import AddressInputPanel from './components/AddressInputPanel'
import ConfirmSwapModal from './components/ConfirmSwapModal'
import CurrencyInputPanelV2 from '../../componentsV2/CurrencyInputPanel/CurrencyInputPanel'

import AdvancedSwapDetailsDropdown from './components/AdvancedSwapDetailsDropdown'
import confirmPriceImpactWithoutFee from './components/confirmPriceImpactWithoutFee'
import { ArrowWrapper, Wrapper } from './components/styleds'
import TradePrice from './components/TradePrice'
import ImportTokenWarningModal from './components/ImportTokenWarningModal'

import { INITIAL_ALLOWED_SLIPPAGE } from '../../config/constants'
import { useCurrency, useAllTokens } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserSlippageTolerance, useUserSingleHopOnly } from '../../state/user/hooks'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import SwapWarningModal from './components/SwapWarningModal'
import UnsupportedCurrencyFooter from './components/UnsupportedCurrencyFooter'
import { Chain } from 'config/constants/types'

export const RoundContainer = styled(Flex)<{ disabled?: boolean; filled?: boolean }>`
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  ${({ filled }) => (filled ? `background: white;` : 'border: 2px solid white;')}
  ${({ disabled, theme }) =>
    disabled &&
    `
      border: none;
      background: ${theme.colorsV2?.main};
    `}
`

export default function Swap({ history }: RouteComponentProps) {
  const loadedUrlParams = useDefaultsFromURLSearch()
  const { address: account } = useAccount()
  const { chain } = useNetwork()
  const chainId = chain?.id
  const { t } = useTranslation()
  useSwapAvailabilityCheck()
  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId, chainId),
    useCurrency(loadedUrlParams?.outputCurrencyId, chainId),
  ]
  const urlLoadedTokens: TokenClass[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is TokenClass => c instanceof TokenClass) ?? [],
    [loadedInputCurrency, loadedOutputCurrency],
  )

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens()
  const importTokensNotInDefault =
    urlLoadedTokens &&
    urlLoadedTokens.filter((token: TokenClass) => {
      return !(token.address in defaultTokens)
    })

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const { v2Trade, currencyBalances, parsedAmount, currencies, inputError: swapInputError } = useDerivedSwapInfo()

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const trade = showWrap ? undefined : v2Trade

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput],
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput],
  )

  // modal and loading
  const [{ tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0)),
  )
  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const { theme } = useTheme()
  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT], chainId)
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(trade, allowedSlippage, recipient)

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const [singleHopOnly] = useUserSingleHopOnly()

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee, t)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then((hash) => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, swapErrorMessage: undefined, txHash: hash })
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
        })
      })
  }, [priceImpactWithoutFee, swapCallback, tradeToConfirm, t])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn })
  }, [attemptingTxn, swapErrorMessage, trade, txHash])

  // swap warning state
  const [swapWarningCurrency, setSwapWarningCurrency] = useState(null)
  const [onPresentSwapWarningModal] = useModal(<SwapWarningModal swapCurrency={swapWarningCurrency} />)

  const shouldShowSwapWarning = (swapCurrency) => {
    const isWarningToken = Object.entries(SwapWarningTokens).find((warningTokenConfig) => {
      const warningTokenData = warningTokenConfig[1]
      return swapCurrency.address === warningTokenData.address
    })
    return Boolean(isWarningToken)
  }

  useEffect(() => {
    if (swapWarningCurrency) {
      onPresentSwapWarningModal()
    }
  }, [swapWarningCurrency])

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
      const showSwapWarning = shouldShowSwapWarning(inputCurrency)
      if (showSwapWarning) {
        setSwapWarningCurrency(inputCurrency)
      } else {
        setSwapWarningCurrency(null)
      }
    },
    [onCurrencySelection],
  )

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact())
    }
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(
    (outputCurrency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency)
      const showSwapWarning = shouldShowSwapWarning(outputCurrency)
      if (showSwapWarning) {
        setSwapWarningCurrency(outputCurrency)
      } else {
        setSwapWarningCurrency(null)
      }
    },

    [onCurrencySelection],
  )

  const swapIsUnsupported = useIsTransactionUnsupported(currencies?.INPUT, currencies?.OUTPUT)

  const [onPresentImportTokenWarningModal] = useModal(
    <ImportTokenWarningModal tokens={importTokensNotInDefault} onCancel={() => history.push('/swap/')} />,
  )

  useEffect(() => {
    if (importTokensNotInDefault.length > 0) {
      onPresentImportTokenWarningModal()
    }
  }, [importTokensNotInDefault.length])

  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false

  const [onPresentConfirmModal] = useModal(
    <ConfirmSwapModal
      trade={trade}
      originalTrade={tradeToConfirm}
      onAcceptChanges={handleAcceptChanges}
      attemptingTxn={attemptingTxn}
      txHash={txHash}
      recipient={recipient}
      allowedSlippage={allowedSlippage}
      onConfirm={handleSwap}
      swapErrorMessage={swapErrorMessage}
      customOnDismiss={handleConfirmDismiss}
    />,
    true,
    true,
    'confirmSwapModal',
  )

  return (
    <>
      <MainTab menu={SWAP_TABS_MENU} />
      <SwapMigrationBanner />
      <Page>
        <AppBody>
          <AppHeader title={t('Swap')} subtitle={t('Exchange tokens in an instant')} />
          <Wrapper id="swap-page">
            <Box />
            <InfoCard>
              {t(
                'This swap product is using native Impossible V3 liquidity, which is still in early stages. Your swaps may be prone to high slippages. Please check before proceeding.',
              )}
            </InfoCard>
            <AutoColumn>
              <Box marginY="10px" />
              <CurrencyInputPanelV2
                label={independentField === Field.OUTPUT && !showWrap && trade ? t('From (estimated)') : t('From')}
                value={formattedAmounts[Field.INPUT]}
                enableCurrencySelect
                hideMaxButton={atMaxAmountInput}
                currency={currencies[Field.INPUT]}
                onUserInput={handleTypeInput}
                onMaxClicked={handleMaxInput}
                onCurrencySelect={handleInputSelect}
                otherCurrency={currencies[Field.OUTPUT]}
                showCommonBases={chainId !== Chain.HUMANODE_TESTNET && chainId !== Chain.HUMANODE_MAINNET}
              />
              <AutoColumn justify="space-between">
                <AutoRow justify="center" style={{ padding: '0 1rem' }}>
                  <ArrowWrapper
                    clickable
                    onClick={() => {
                      setApprovalSubmitted(false) // reset 2 step UI for approvals
                      onSwitchTokens()
                    }}
                  >
                    <SwapVertIcon width="23px" color="textSubtle" />
                  </ArrowWrapper>
                </AutoRow>
              </AutoColumn>
              <CurrencyInputPanelV2
                label={independentField === Field.INPUT && !showWrap && trade ? t('To (estimated)') : t('To')}
                value={formattedAmounts[Field.OUTPUT]}
                enableCurrencySelect
                hideMaxButton
                currency={currencies[Field.OUTPUT]}
                onUserInput={handleTypeOutput}
                onCurrencySelect={handleOutputSelect}
                otherCurrency={currencies[Field.INPUT]}
                showCommonBases={chainId !== Chain.HUMANODE_TESTNET && chainId !== Chain.HUMANODE_MAINNET}
              />

              {recipient === null && !showWrap && isExpertMode ? (
                <Button variant="toastAction" id="add-recipient-button" onClick={() => onChangeRecipient('')}>
                  <IFTypography variant="body2">{t('+ Add a send (optional)')}</IFTypography>
                </Button>
              ) : null}
              {isExpertMode && recipient !== null && !showWrap ? (
                <>
                  <AutoRow justify="center" style={{ padding: '0 1rem' }}>
                    <Flex marginY="20px">
                      <ArrowWrapper clickable={false}>
                        <ArrowDownIcon width="16px" />
                      </ArrowWrapper>
                    </Flex>
                    <Flex position="absolute" right="40px">
                      <Button
                        variant="toastAction"
                        id="remove-recipient-button"
                        onClick={() => onChangeRecipient(null)}
                        style={{ alignItems: 'flex-end' }}
                      >
                        <IFTypography variant="caption">{t('Remove send')}</IFTypography>
                      </Button>
                    </Flex>
                  </AutoRow>
                  <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                </>
              ) : null}
              <Box marginY="9px" />
              {showWrap ? null : (
                <AutoColumn gap="18px" style={{ padding: '0 16px' }}>
                  {Boolean(trade) && (
                    <RowBetween align="center">
                      <IFTypography variant="body2">{t('Price')}</IFTypography>
                      <TradePrice
                        price={trade?.executionPrice}
                        showInverted={showInverted}
                        setShowInverted={setShowInverted}
                      />
                    </RowBetween>
                  )}
                  {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                    <RowBetween align="center">
                      <IFTypography variant="body2">{t('Slippage Tolerance')}</IFTypography>
                      <IFTypography variant="body2" fontWeight="bold">
                        {allowedSlippage / 100}%
                      </IFTypography>
                    </RowBetween>
                  )}
                </AutoColumn>
              )}
            </AutoColumn>
            <Box mt="1rem" display="flex" style={{ justifyContent: 'center' }}>
              {swapIsUnsupported ? (
                <Button width="100%" disabled mb="4px">
                  {t('Unsupported Asset')}
                </Button>
              ) : !account ? (
                <ConnectWalletButton width="300px" />
              ) : showWrap ? (
                <Button width="100%" disabled={Boolean(wrapInputError)} onClick={onWrap}>
                  {wrapInputError ??
                    (wrapType === WrapType.WRAP ? t('Wrap') : wrapType === WrapType.UNWRAP ? t('Unwrap') : null)}
                </Button>
              ) : noRoute && userHasSpecifiedInputOutput ? (
                <Button width="100%" disabled mb="4px">
                  {t('Insufficient liquidity for this trade.')}
                  {singleHopOnly && t('Try enabling multi-hop trades.')}
                </Button>
              ) : showApproveFlow ? (
                <Flex flexDirection={isMobile ? 'column' : 'row'}>
                  <Button
                    variant={approval === ApprovalState.APPROVED ? 'success' : 'primary'}
                    onClick={approveCallback}
                    isLoading={approval === ApprovalState.PENDING}
                    disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                    width="100%"
                    startIcon={
                      approval === ApprovalState.PENDING ? (
                        <WhiteCircleLoader size="24px" />
                      ) : (
                        <RoundContainer
                          disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                          filled={approval === ApprovalState.APPROVED}
                        >
                          {approval === ApprovalState.APPROVED ? (
                            <CheckmarkIcon width="14px" color={theme.colorsV2?.textDisabled} />
                          ) : (
                            '1'
                          )}
                        </RoundContainer>
                      )
                    }
                  >
                    {approval === ApprovalState.PENDING
                      ? t('Approving')
                      : approvalSubmitted && approval === ApprovalState.APPROVED
                      ? t('Approved')
                      : t('Approve')}
                  </Button>
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    px="8px"
                    py="3px"
                    style={{ transform: isMobile && 'rotate(90deg)' }}
                  >
                    <PolygonRightIcon color={theme.isDark ? theme.colorsV2?.light : theme.colorsV2?.textDisabled} />
                  </Flex>
                  <Button
                    startIcon={
                      <RoundContainer
                        disabled={
                          !isValid || approval !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode)
                        }
                      >
                        2
                      </RoundContainer>
                    }
                    variant="primary"
                    onClick={() => {
                      setSwapState({
                        tradeToConfirm: trade,
                        attemptingTxn: false,
                        swapErrorMessage: undefined,
                        txHash: undefined,
                      })
                      onPresentConfirmModal()
                    }}
                    width="100%"
                    id="swap-button"
                    disabled={
                      !isValid || approval !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode)
                    }
                  >
                    {priceImpactSeverity > 3 && !isExpertMode
                      ? t('Price Impact High')
                      : priceImpactSeverity > 2
                      ? t('Swap Anyway')
                      : t('Swap')}
                  </Button>
                </Flex>
              ) : (
                <Button
                  variant="primary"
                  onClick={() => {
                    setSwapState({
                      tradeToConfirm: trade,
                      attemptingTxn: false,
                      swapErrorMessage: undefined,
                      txHash: undefined,
                    })
                    onPresentConfirmModal()
                  }}
                  id="swap-button"
                  width="100%"
                  disabled={
                    !isValid ||
                    approval !== ApprovalState.APPROVED ||
                    (priceImpactSeverity > 3 && !isExpertMode) ||
                    !!swapCallbackError
                  }
                >
                  {swapInputError ||
                    (priceImpactSeverity > 3 && !isExpertMode
                      ? t('Price Impact Too High')
                      : priceImpactSeverity > 2
                      ? t('Swap Anyway')
                      : t('Swap'))}
                </Button>
              )}
              {isValid && priceImpactSeverity > 2 && !isExpertMode && wrapType !== WrapType.WRAP && (
                <Flex mt="2px" justifyContent="center">
                  <IFTypography ifcolor="textWarning">{t('high price impact')}</IFTypography>
                </Flex>
              )}
            </Box>
          </Wrapper>
        </AppBody>
        {!swapIsUnsupported ? (
          <AdvancedSwapDetailsDropdown trade={trade} />
        ) : (
          <UnsupportedCurrencyFooter currencies={[currencies.INPUT, currencies.OUTPUT]} />
        )}
      </Page>
    </>
  )
}
