import { darkColorsV2, lightColorsV2, blueColorsV2, beamColorsV2, carvColorsV2 } from '../../theme/colorsV2'
import { NavTheme } from './types'

export const light: NavTheme = {
  background: lightColorsV2.main,
  linkText: lightColorsV2.textSecondary,
  activeLinkText: lightColorsV2.gradients.green2lightblue,
  toggleTheme: lightColorsV2.dark2,
  dropdownShadow: lightColorsV2.shadows.settings,
  dropdownBorder: '#E0E0E0',
  hover: '#EEEAF4',
}

export const dark: NavTheme = {
  background: darkColorsV2.main,
  linkText: darkColorsV2.textThirdly,
  activeLinkText: lightColorsV2.gradients.green2lightblue,
  toggleTheme: darkColorsV2.main,
  dropdownShadow: darkColorsV2.shadows.settings,
  dropdownBorder: '#555A9B',
  hover: '#473d5d',
}

export const blue: NavTheme = {
  background: blueColorsV2.main,
  linkText: blueColorsV2.textSecondary,
  activeLinkText: lightColorsV2.gradients.green2lightblue,
  toggleTheme: blueColorsV2.dark2,
  dropdownShadow: blueColorsV2.shadows.settings,
  dropdownBorder: '#E0E0E0',
  hover: '#EEEAF4',
}

export const beam: NavTheme = {
  background: beamColorsV2.main,
  linkText: 'rgba(255, 255, 255, 0.30)',
  activeLinkText: beamColorsV2.textLink,
  toggleTheme: beamColorsV2.dark2,
  dropdownShadow: beamColorsV2.shadows.settings,
  dropdownBorder: '#596187',
  hover: '#EEEAF4',
}

export const carv: NavTheme = {
  background: carvColorsV2.main,
  linkText: 'rgba(255, 255, 255, 0.30)',
  activeLinkText: carvColorsV2.textLink,
  toggleTheme: carvColorsV2.dark2,
  dropdownShadow: carvColorsV2.shadows.settings,
  dropdownBorder: '#596187',
  hover: '#EEEAF4',
}
