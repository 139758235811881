import React, { useEffect, lazy, useContext } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { ResetCSS, ModalProvider } from 'uikit'
import { DrawerProvider } from 'uikit/widgets/Drawer'
import { setWithExpiry } from 'utils/localStorageHelper'
import spindl from '@spindl-xyz/attribution'

import Footer from 'uikit/widgets/Footer'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import useGetDocumentTitlePrice from 'hooks/useGetDocumentTitlePrice'
import { useFetchPublicData } from 'state/hooks'
import { usePollBlockNumber } from 'state/block/hooks'
import { useFetchIdoUserData, useFetchInitIdosPublicData } from 'state/idos/hooks'
import {
  RedirectDuplicateTokenIds,
  RedirectLpTokenId,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from 'views/AddLiquidity/redirects'
import Manage from 'views/Manage'
import RedirectOldRemoveLiquidityPathStructure from 'views/RemoveLiquidity/redirects'
import RemoveLiquidity from 'views/RemoveLiquidity'
import Swap from 'views/Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from 'views/Swap/redirects'
import CheckBlackList from 'componentsV2/CheckBlackList'

import { type ConnectorData, useAccount } from 'wagmi'

import { RAINBOW_KIT_FATHOM_ID } from './constants'
import GlobalStyle from './style/Global'
import Menu from './componentsV2/Menu'
import SuspenseWithChunkError from './componentsV2/SuspenseWithChunkError'
import ToastListener from './componentsV2/ToastListener'
import PageLoader from './componentsV2/Loader/PageLoader'
import history from './routerHistory'
import ScrollToTop from './ScrollToTop'
import useAuth from 'hooks/useAuth'
import useReferral from 'hooks/useReferral'
import { Brands, CompanyContext } from 'contexts/CompanyContext'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
// const Farms = lazy(() => import('./views/Farms'))
const Vest = lazy(() => import('./views/Vest'))
const Explore = lazy(() => import('./views/Explore'))
const Quest = lazy(() => import('./views/Quest'))
const QuestDetail = lazy(() => import('./views/Quest/Detail'))
const QuestInfo = lazy(() => import('./views/Quest/Info'))
const QuestResult = lazy(() => import('./views/Quest/Result'))
const Earn = lazy(() => import('./views/Earn'))
const Project = lazy(() => import('./views/Project'))
const JustSale = lazy(() => import('./views/Project'))

const Sale = lazy(() => import('./views/Sale'))
const NotFound = lazy(() => import('./views/NotFound'))
const AddLiquidity = lazy(() => import('./views/AddLiquidity'))
const Liquidity = lazy(() => import('./views/Pool'))
const PoolFinder = lazy(() => import('./views/PoolFinder'))
const Frill = lazy(() => import('./views/Frill'))
const SignIn = lazy(() => import('./views/SignIn'))
const KYC = lazy(() => import('./views/KYC'))
const SwapNotSupported = lazy(() => import('./views/SwapNotSupported'))
const KYCNotSupported = lazy(() => import('./views/KYCNotSupported'))

// const WillBeBack = lazy(() => import('./views/WillBeBack'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  // Monkey patch warn() because of web3 flood
  // To be removed when web3 1.3.5 is released
  useEffect(() => {
    console.warn = () => null
  }, [])

  useFetchInitIdosPublicData()
  useFetchIdoUserData()
  usePollBlockNumber()
  useEagerConnect()
  useFetchPublicData()
  useGetDocumentTitlePrice()

  const { logout } = useAuth()
  const { sendReferral } = useReferral()
  const { brand, isJustSale } = useContext(CompanyContext)

  // Only for Aethir
  if (brand === Brands.AETHIR) {
    spindl.configure({
      sdkKey: '5d07a32e-d5fe-4a61-acba-67a717096a2e',
      debugMode: process.env.REACT_APP_STAGING ? false : true, // we recommend only to have debugMode=true when testing.
      // you will see console.logs of emitted events in browser
    })

    spindl.enableAutoPageViews()
  }

  const { connector: activeConnector } = useAccount()

  useEffect(() => {
    const handleConnectorUpdate = ({ account, chain }: ConnectorData) => {
      if (account) {
        // Remove the sign in status on wallet change
        logout()
      } else if (chain) {
        console.log('new chain', chain)
      }
    }

    if (activeConnector) {
      activeConnector.on('change', handleConnectorUpdate)
    }

    return () => {
      activeConnector?.off('change', handleConnectorUpdate)
    }
  }, [activeConnector])

  useAccount({
    onConnect({ address, connector }) {
      const url = new URL(window.location.href)
      const c = url.searchParams.get('c')

      if (address?.length > 0 && c?.length > 0) {
        sendReferral({
          code: c,
          action: 'CONNECT_WALLET',
          address,
        })
      }
      if (RAINBOW_KIT_FATHOM_ID[connector.name] && window.fathom) {
        window.fathom.trackGoal(RAINBOW_KIT_FATHOM_ID[connector.name], 0)
      }

      if (brand === Brands.AETHIR) {
        spindl.attribute(address)
      }
    },
    onDisconnect() {
      logout()
    },
  })

  const url = new URL(window.location.href)
  const c = url.searchParams.get('code')?.toLowerCase() || ''

  useEffect(() => {
    if (c?.length > 0) {
      setWithExpiry('PROMO_CODE', c, 30 * 24 * 60 * 1000, 1)
    }
  }, [c])

  return (
    <Router history={history}>
      <ModalProvider>
        <ResetCSS />
        <GlobalStyle />
        <DrawerProvider>
          <CheckBlackList>
            <Menu>
              <SuspenseWithChunkError fallback={<PageLoader />}>
                <ScrollToTop />
                <Switch>
                  {isJustSale ? (
                    <>
                      <Route path="/" exact>
                        <JustSale />
                      </Route>
                      <Route path="/sale/:saleID" component={Sale} exact />
                    </>
                  ) : (
                    <>
                      <Route path="/" exact>
                        <Redirect to="/explore" />
                      </Route>
                      <Route path="/launchpad/project/:projectID" component={Project} exact />
                      <Route path="/launchpad/project/:projectID/sale/:saleID" component={Sale} exact />
                      <Route path="/explore">
                        <Explore />
                      </Route>
                      <Route path="/manage">
                        <Manage />
                      </Route>
                      {brand === Brands.IF && (
                        <>
                          <Route path="/vesting">
                            <Vest />
                          </Route>
                          <Route path="/swapNotSupported" exact component={SwapNotSupported} />
                          <Route exact strict path="/swap" component={Swap} />
                          <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                          <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                          <Route exact strict path="/liquidity/find" component={PoolFinder} />
                          <Route exact strict path="/liquidity" component={Liquidity} />
                          <Route exact strict path="/create" component={RedirectToAddLiquidity} />
                          <Route exact path="/liquidity/add" component={AddLiquidity} />
                          <Route
                            exact
                            path="/liquidity/add/:currencyIdA"
                            component={RedirectOldAddLiquidityPathStructure}
                          />
                          <Route
                            exact
                            path="/liquidity/add/:currencyIdA/:currencyIdB"
                            component={RedirectDuplicateTokenIds}
                          />
                          <Route exact path="/liquidity/addLP/:lpTokenId" component={RedirectLpTokenId} />
                          <Route exact path="/liquidity/create" component={AddLiquidity} />
                          <Route
                            exact
                            path="/liquidity/create/:currencyIdA"
                            component={RedirectOldAddLiquidityPathStructure}
                          />
                          <Route
                            exact
                            path="/liquidity/create/:currencyIdA/:currencyIdB"
                            component={RedirectDuplicateTokenIds}
                          />
                          <Route
                            exact
                            strict
                            path="/liquidity/remove/:tokens"
                            component={RedirectOldRemoveLiquidityPathStructure}
                          />
                          <Route
                            exact
                            strict
                            path="/liquidity/remove/:currencyIdA/:currencyIdB"
                            component={RemoveLiquidity}
                          />
                          <Route path="/pool">
                            <Redirect to="/liquidity" />
                          </Route>
                          <Route exact strict path="/signin">
                            <SignIn />
                          </Route>
                          <Route exact strict path="/kyc">
                            <KYC />
                          </Route>
                          {process.env.REACT_APP_ENABLE_QUEST && (
                            <Route exact strict path="/quests">
                              <Quest />
                            </Route>
                          )}
                          {process.env.REACT_APP_ENABLE_QUEST && (
                            <Route exact strict path="/quests/:questId/quiz">
                              <QuestDetail />
                            </Route>
                          )}
                          {process.env.REACT_APP_ENABLE_QUEST && (
                            <Route exact strict path="/quests/:questId/result">
                              <QuestResult />
                            </Route>
                          )}
                          {process.env.REACT_APP_ENABLE_QUEST && (
                            <Route exact strict path="/quests/:questId/info">
                              <QuestInfo />
                            </Route>
                          )}
                          <Route exact strict path="/sso/frill">
                            <Frill />
                          </Route>
                          <Route exact path="/rewards" component={Earn} />
                        </>
                      )}
                    </>
                  )}
                  {/* 404 */}
                  <Route component={NotFound} />
                </Switch>
                <Footer />
              </SuspenseWithChunkError>
            </Menu>
            <ToastListener />
          </CheckBlackList>
        </DrawerProvider>
      </ModalProvider>
    </Router>
  )
}

export default React.memo(App)
