import React from 'react'
import styled from 'styled-components'
import { PolymorphicComponent, BaseButtonProps } from 'uikit/components/Button/types'
import useMatchBreakpoints from '../../hooks/useMatchBreakpoints'
import Flex from '../../components/Box/Flex'
import { Box } from '../../components/Box'
import { Button } from '../../components/Button'
import { ArrowBackIcon, CloseIcon } from '../../components/Svg'
import { ModalProps } from './types'

export const ModalHeader = styled.div<{ background?: string }>`
  align-items: center;
  background: ${({ background }) => background || 'transparent'};
  display: flex;
  padding: 30px 20px 15px;
  ${({ theme }) => theme.mediaQueries?.sm} {
    padding: 37px 40px 12px;
  }
`

export const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
`

export const ModalBody = styled(Flex)`
  flex-direction: column;
  overflow-y: auto;
`

const IconButton: PolymorphicComponent<BaseButtonProps, 'button'> = styled(Button)<BaseButtonProps>`
  padding: 10px;
  ${({ theme }) => theme.mediaQueries?.sm} {
    padding: 0px;
  }
`

export const CloseIconSvg = styled(CloseIcon)`
  color: ${({ theme }) => theme.colorsV2?.text};
`

export const ModalCloseButton: React.FC<{ onDismiss: ModalProps['onDismiss'] }> = ({ onDismiss }) => {
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl
  return (
    <Flex mr={isMobile && '-7px'}>
      <IconButton variant="text" onClick={onDismiss} aria-label="Close the dialog">
        <CloseIconSvg width={isMobile ? '14px' : '19px'} />
      </IconButton>
    </Flex>
  )
}

export const ModalBackButton: React.FC<{ onBack: ModalProps['onBack'] }> = ({ onBack }) => {
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl
  return (
    <Flex ml="-8px" mr={isMobile ? '15px' : '25px'}>
      <IconButton variant="text" onClick={onBack} area-label="go back">
        <ArrowBackIcon color="text" width={isMobile ? '16px' : '21px'} />
      </IconButton>
    </Flex>
  )
}

export const ModalContainer = styled(Box)<{
  minContainerWidth?: string
  overflowMobile?: string
  $fixHeaderMobile?: boolean
}>`
  overflow: hidden;
  background: ${({ theme }) => theme.modal.background};
  box-shadow: ${({ theme }) => theme.modal.boxShadow};
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  overflow-y: hidden;
  max-height: 80vh;
  z-index: ${({ theme }) => theme.zIndices.modal};
  position: absolute;
  bottom: 0px;

  @media (max-width: 575px) {
    ${({ overflowMobile, $fixHeaderMobile }) => !$fixHeaderMobile && `overflow-y: ${overflowMobile}`};
  }

  ${({ theme }) => theme.mediaQueries?.sm} {
    width: auto;
    ${({ minContainerWidth }) => `min-width: ${minContainerWidth}`};
    max-width: 500px;
    position: inherit;
    bottom: auto;
    border-radius: 20px;
    height: auto;
  }
`
