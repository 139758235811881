import React from 'react'
import Svg from '../../../components/Svg/Svg'

const WalletWhite: React.FC = ({ ...props }) => {
  return (
    <Svg width="19" height="14" viewBox="0 0 19 14" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4229 5.5H13.4229C12.5944 5.5 11.9229 6.17157 11.9229 7C11.9229 7.82843 12.5944 8.5 13.4229 8.5H17.4229V5.5ZM13.4229 4C11.766 4 10.4229 5.34315 10.4229 7C10.4229 8.65685 11.766 10 13.4229 10H17.9229C18.4751 10 18.9229 9.55228 18.9229 9V5C18.9229 4.44772 18.4751 4 17.9229 4H13.4229Z"
        fill="#FAFAFA"
      />
      <path
        d="M2.42285 12.5V1.5H16.4229V4H17.9229V2C17.9229 1 17.4229 0 15.9229 0H2.92285C1.92285 0 0.922852 0.5 0.922852 2L0.922852 12C0.922852 13.5 1.92285 14 2.92285 14H15.9229C16.9229 14 17.9229 13.5 17.9229 12V10H16.4229V12.5H2.42285Z"
        fill="#FAFAFA"
      />
    </Svg>
  )
}

export default React.memo(WalletWhite)
