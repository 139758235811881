import React, { useContext, useState } from 'react'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import light from 'uikit/theme/light'
import ronin from 'uikit/theme/ronin'
import beam from 'uikit/theme/beam'
import aethir from 'uikit/theme/aethir'
import dark from 'uikit/theme/dark'
import carv from 'uikit/theme/carv'
import { Brands, CompanyContext } from './CompanyContext'

const CACHE_KEY = 'IS_DARK'

const ThemeContext = React.createContext({ isDark: true, toggleTheme: () => null })

const ThemeContextProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(() => {
    const isDarkUserSetting = localStorage.getItem(CACHE_KEY)
    return isDarkUserSetting ? JSON.parse(isDarkUserSetting) : false
  })

  const toggleTheme = () => {
    setIsDark((prevState) => {
      localStorage.setItem(CACHE_KEY, JSON.stringify(!prevState))
      return !prevState
    })
  }

  const { themeName, brand } = useContext(CompanyContext)
  let theme = isDark ? dark : light

  let isDarkOverride = false
  if (themeName === 'ronin') {
    theme = ronin
    isDarkOverride = false
  }

  if (themeName === Brands.AETHIR || themeName === Brands.SOPHON) {
    theme = aethir
    isDarkOverride = false
  }

  if (themeName === 'beam') {
    theme = beam
    isDarkOverride = true
  }
  if (themeName === 'carv') {
    theme = carv
    isDarkOverride = true
  }

  return (
    <ThemeContext.Provider value={{ isDark: brand === Brands.IF ? isDark : isDarkOverride, toggleTheme }}>
      <SCThemeProvider theme={theme}>{children}</SCThemeProvider>
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeContextProvider }
