import React, { useContext } from 'react'
import { Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ArrowDown from '@mui/icons-material/ArrowDropDownSharp'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import styled from 'styled-components'
import useMatchBreakpoints from 'uikit/hooks/useMatchBreakpoints'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Brands, CompanyContext } from 'contexts/CompanyContext'
export type AccordionTheme = {
  background: string
  headerColor: string
  headerBackground: string
  summaryColor: string
}

export type AccordionItem = {
  key: string
  title: string
  content?: string
  steps?: StepsItem[]
}

type StepsItem = {
  title?: string
  children?: JSX.Element
}

type IFMultipleAccordionProps = {
  title: string
  items: AccordionItem[]
}

const IFAccordion = styled((props) => <Accordion disableGutters elevation={0} square {...props} />)(
  ({ theme, isMobile }) => ({
    marginBottom: '0 !important',
    marginTop: '0 !important',
    padding: isMobile ? '0 10px' : '0 20px',
    backgroundColor: `${theme.accordion.background} !important`,
    '& .MuiAccordion-root.Mui-expanded': {
      marginBottom: 0,
    },
    '&:last-child': {
      borderRadius: '0 0 20px 20px',
    },
    '&:before': {
      display: 'none',
    },
  }),
)

const IFAccordionSummary = styled((props) => {
  const { brand } = useContext(CompanyContext)
  const isBeam = brand === Brands.BEAM
  return <AccordionSummary expandIcon={isBeam ? <KeyboardArrowDownIcon /> : <ArrowDown />} {...props} />
})(({ theme }) => ({
  padding: 0,
  backgroundColor: 'transparent',
  '& p': {
    color: `${theme.accordion.summaryColor}`,
  },
  '& svg': {
    color: `${theme.common.insctructionText}`,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    marginLeft: 12,
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: 12,
  },
}))

const IFAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 8,
  paddingLeft: '10px !important',
  borderLeft: `2px solid ${theme.common?.borderLeftAccordion};`,
  marginLeft: 20,
  marginBottom: 20,
  lineHeight: '20px',
  color: `${theme.colorsV2?.textSecondary}`,
  '.children': {
    marginBottom: 20,
    ':last-child': {
      marginBottom: 0,
    },
  },
  '& p': {
    marginBottom: 10,
  },
  '.small': {
    fontSize: 14,
  },
  '.warning': {
    maxWidth: '100%',
    width: 'max-content',
    borderRadius: 5,
    margin: '25px 0',
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: `${theme.colorsV2?.feedbacks?.dangerBg}`,
    color: `${theme.colorsV2?.feedbacks?.dangerText}`,

    '& svg': {
      fill: `${theme.colorsV2?.feedbacks?.dangerText}`,
      marginRight: 10,
    },
  },
  '& a': {
    color: `${theme.colorsV2?.textLink}`,
    textDecoration: 'underline',
    '& svg': {
      fill: `${theme.colorsV2?.textLink}`,
      width: '12px !important',
      marginLeft: 5,
    },
  },

  '& ol': {
    paddingLeft: '20px',
    listStyle: 'auto',
    fontSize: '14px',

    '& li': {
      marginTop: '10px',

      '& svg': {
        width: '20px',
        margin: '0 5px',
      },
    },
  },
  '& ul': {
    listStyle: 'none',
    paddingLeft: '15px',
    marginBottom: '10px',
    '& li': {
      marginTop: '10px',
      lineHeight: '20px',
      fontSize: '14px',
      '&:before': {
        content: '"・"',
        fontWeight: 'bold',
      },
    },
  },
}))

const AccordionHeader = styled(Box)`
  padding: 20px 40px;
  background: ${({ theme }) => theme.accordion.headerBackground};
  border-radius: 20px 20px 0px 0px;

  font-weight: 700;
  color: ${({ theme }) => theme.accordion.headerColor};
  font-size: 24px;
`

const DividerBox = styled(Box)`
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2?.dark2 : theme.colorsV2.background)};
`

const Divider = styled(Box)`
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2.main3 : theme.colorsV2?.dark2)};
  width: 100%;
  height: 2px;
`

const IFMultipleAccordion: React.FC<IFMultipleAccordionProps> = ({ title, items }) => {
  const [expanded, setExpanded] = React.useState('')
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl

  return (
    <Box>
      <AccordionHeader>{title}</AccordionHeader>
      {items.map((item, index) => {
        return (
          <React.Fragment key={item.key}>
            <IFAccordion isMobile={isMobile} expanded={expanded === item.key} onChange={handleChange(item.key)}>
              <IFAccordionSummary aria-controls={`${item.key}-content`} id={`${item.key}-header`}>
                <IFTypography variant="body2" fontWeight="bold">
                  {item.title}
                </IFTypography>
              </IFAccordionSummary>
              <IFAccordionDetails>
                {item.content && <IFTypography variant="body1">{item.content}</IFTypography>}
                {item.steps &&
                  item.steps.map((step) => {
                    return (
                      <React.Fragment key={step.title}>
                        {step.title && (
                          <IFTypography variant="body1" fontWeight="bold">
                            {step.title}
                          </IFTypography>
                        )}
                        <div className="children">{step.children}</div>
                      </React.Fragment>
                    )
                  })}
              </IFAccordionDetails>
            </IFAccordion>
            {index < items.length - 1 && (
              <DividerBox>
                <Divider />
              </DividerBox>
            )}
          </React.Fragment>
        )
      })}
    </Box>
  )
}

export default IFMultipleAccordion
