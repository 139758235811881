// import { Brands } from 'contexts/CompanyContext'
import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from 'uikit/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Acre';
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/Acre-Regular.ttf) ;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Acre';
    font-style: normal;
    font-weight: 500;
    src: url(/fonts/Acre-Medium.ttf) ;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Acre';
    font-style: normal;
    font-weight: 700;
    src: url(/fonts/Acre-Bold.ttf) ;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Acre';
    font-style: normal;
    font-weight: 900;
    src: url(/fonts/Acre-Extrabold.ttf);
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Arca Majora 3';
    font-style: normal;
    font-weight: 700;
    src: url(/fonts/ArcaMajora3-Bold.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: 'Arca Majora 3';
    font-style: normal;
    font-weight: 900;
    src: url(/fonts/ArcaMajora3-Heavy.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  * {
    font-family: Roboto, PingFang, "Helvetica Neue", Helvetica, Arial;
  }
  body {
    background-color: ${({ theme }) => theme.colorsV2.dark};
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-image: ${({ theme }) =>
      theme.brand === 'if' ? `url('/images/background-${theme.isDark ? 'dark' : 'light'}.svg')}` : 'none'};

    background-image: ;
    img {
      height: auto;
      max-width: 100%;
    }
  }
  input[type=text] {   
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    
  }
  input {
    margin: 0;
    padding: 0;
  }
  .Frill_Trigger_Tab {
    z-index: 80 !important;
  }
`

export default GlobalStyle
