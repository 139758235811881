// Step 7 add more chains
import { Chain } from './types'

export type Network = {
  chainID: Chain
  iconSrc: string
  name: string
  fullName?: string
  backgroundColor: string
  alertBorder: string
  alertBackground: string
}

const networkModalOptions: Network[] = [
  {
    chainID: Chain.ETH_MAINNET,
    iconSrc: '/images/Ethereum.svg',
    name: 'Ethereum',
    backgroundColor: '#627EEA',
    alertBorder: '#627EEA',
    alertBackground: '#627EEA',
  },
  {
    chainID: Chain.POLYGON_MAINNET,
    iconSrc: '/images/Polygon.svg',
    name: 'Polygon',
    backgroundColor: '#8247E54D',
    alertBorder: '#8247E5',
    alertBackground: '#8247E54D',
  },
  {
    chainID: Chain.ARBITRUM_MAINNET,
    iconSrc: '/images/arbitrum.svg',
    name: 'Arbitrum',
    fullName: 'Arbitrum',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
  {
    chainID: Chain.BSC_MAINNET,
    iconSrc: '/images/BSC.svg',
    name: 'Binance',
    fullName: 'BNB Chain',
    backgroundColor: '#F0B90B',
    alertBorder: '#F0B90B',
    alertBackground: '#F0B90B',
  },
  {
    chainID: Chain.MOONBEAM_MAINNET,
    iconSrc: '/images/Moonbeam.svg',
    name: 'Moonbeam',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
  {
    chainID: Chain.ARBITRUM_SEPOLIA,
    iconSrc: '/images/arbitrum.svg',
    name: 'Arbitrum Goerli',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
  {
    chainID: Chain.LINEA_TESTNET,
    iconSrc: '/images/linea.jpg',
    name: 'Linea Testnet',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
  {
    chainID: Chain.AURORA_MAINNET,
    iconSrc: '/images/Aurora.svg',
    name: 'Aurora',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
  process.env.REACT_APP_TEST_CHAINS && {
    chainID: Chain.BSC_TESTNET,
    iconSrc: '/images/BSCTest.svg',
    name: 'BSC Testnet',
    fullName: 'BSC Testnet Chain',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
  {
    chainID: Chain.MANTLE,
    iconSrc: '/images/mantle.jpeg',
    name: 'Mantle',
    fullName: 'Mantle',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
  {
    chainID: Chain.ZKSYNC_ERA_MAINNET,
    iconSrc: '/images/icons/networks/300.svg',
    name: 'ZkSync Era Mainnet',
    fullName: 'ZkSync Era Mainnet',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
  process.env.REACT_APP_TEST_CHAINS && {
    chainID: Chain.ZKSYNC_SEPOLIA,
    iconSrc: '/images/icons/networks/300.svg',
    name: 'ZkSync Sepolia',
    fullName: 'ZkSync Sepolia',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
  process.env.REACT_APP_STAGING && {
    chainID: Chain.OPTIMISM_MAINNET,
    iconSrc: '/images/optimism.svg',
    name: 'Optimism',
    fullName: 'Optimism Mainnet Chain',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },

  {
    chainID: Chain.RONIN,
    iconSrc: '/images/icons/networks/2021.svg',
    name: 'RONIN',
    fullName: 'Ronin Chain',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
  {
    chainID: Chain.SAIGON,
    iconSrc: '/images/icons/networks/2021.svg',
    name: 'SAIGON Testnet',
    fullName: 'Saigon Testnet Chain',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
  process.env.REACT_APP_STAGING && {
    chainID: Chain.NEON_DEVNET,
    iconSrc: '/images/NeonLabs.svg',
    name: 'Neon Devnet',
    fullName: 'Neon Devnet',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
  process.env.REACT_APP_STAGING && {
    chainID: Chain.HUMANODE_TESTNET,
    iconSrc: '/images/hmnd.png',
    name: 'Humanode Testnet',
    fullName: 'Humanode Testnet',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
  {
    chainID: Chain.OKX_TESTNET,
    iconSrc: '/images/tokens/OKB.png',
    name: 'OKX Testnet',
    fullName: 'OKX Testnet',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
  {
    chainID: Chain.HUMANODE_MAINNET,
    iconSrc: '/images/hmnd.png',
    name: 'Humanode Mainnet',
    fullName: 'Humanode Mainnet',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
  {
    chainID: Chain.MANTA_MAINNET,
    iconSrc: '/images/hmnd.png',
    name: 'Manta',
    fullName: 'Manta',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
  {
    chainID: Chain.BASE_SEPOLIA,
    iconSrc: '/images/icons/networks/169.svg',
    name: 'Base Goerli',
    fullName: 'Base Goerli',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
  {
    chainID: Chain.BEAM,
    iconSrc: '/images/icons/networks/169.svg',
    name: 'Beam',
    fullName: 'Beam',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
  {
    chainID: Chain.BEAM_TEST,
    iconSrc: '/images/icons/networks/169.svg',
    name: 'Beam Test',
    fullName: 'Beam Test',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
  {
    chainID: Chain.IMMUTABLE,
    iconSrc: '/images/icons/networks/169.svg',
    name: 'Immutable',
    fullName: 'Immutable',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
  {
    chainID: Chain.IMMUTABLE_TEST,
    iconSrc: '/images/icons/networks/169.svg',
    name: 'Immutable Test',
    fullName: 'Immutable Test',
    backgroundColor: '#53CBC8',
    alertBorder: '#53CBC8',
    alertBackground: '#53CBC84D',
  },
].filter((val) => val)

export default networkModalOptions
