import { beamColorsV2, darkColorsV2, lightColorsV2, carvColorsV2 } from 'uikit/theme/colorsV2'
import { AccordionTheme } from './IFAccordion'

export const light: AccordionTheme = {
  background: lightColorsV2.background,
  headerColor: lightColorsV2.textSecondary,
  headerBackground: lightColorsV2.dark2,
  summaryColor: lightColorsV2.text,
}
export const dark: AccordionTheme = {
  background: darkColorsV2.dark2,
  headerColor: darkColorsV2.textThirdly,
  headerBackground: darkColorsV2.main3,
  summaryColor: darkColorsV2.textThirdly,
}
export const blue: AccordionTheme = {
  background: lightColorsV2.background,
  headerColor: lightColorsV2.dark2,
  headerBackground: lightColorsV2.dark2,
  summaryColor: lightColorsV2.text,
}
export const beam: AccordionTheme = {
  background: beamColorsV2.main3,
  headerColor: beamColorsV2.text,
  headerBackground: beamColorsV2.main,
  summaryColor: beamColorsV2.text,
}

export const carv: AccordionTheme = {
  background: carvColorsV2.main3,
  headerColor: carvColorsV2.text,
  headerBackground: carvColorsV2.main,
  summaryColor: carvColorsV2.text,
}
