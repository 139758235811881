import React, { useContext } from 'react'
import styled, { keyframes } from 'styled-components'
import { CompanyContext, Brands } from 'contexts/CompanyContext'
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`

const StyledImage = styled.img<{ size: string; stroke?: string }>`
  animation: 2s ${rotate} linear infinite;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
export default function CircleLoader({
  size = '16px',
  stroke,
  ...rest
}: {
  size?: string
  stroke?: string
  [k: string]: any
}) {
  const { brand, isJustSale } = useContext(CompanyContext)
  return (
    <StyledImage
      src={`/images/icons/${
        brand === Brands.RONIN ? 'CircleLoaderRonin' : isJustSale ? 'LoadingSpinnerAethir' : 'Loading'
      }.png`}
      size={size}
      stroke={stroke}
      {...rest}
    />
  )
}
