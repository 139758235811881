import { createTheme } from '@mui/material/styles'

import AcreRegular from './fonts/Acre-Regular.ttf'
import AcreMedium from './fonts/Acre-Medium.ttf'
import AcreBold from './fonts/Acre-Bold.ttf'
import AcreExtrabold from './fonts/Acre-Extrabold.ttf'
import ArcaMajoraBold from './fonts/ArcaMajora3-Bold.woff'
import ArcaMajoraHeavy from './fonts/ArcaMajora3-Heavy.woff'

const acreFontsList = [
  { fontWeight: 400, file: AcreRegular },
  { fontWeight: 500, file: AcreMedium },
  { fontWeight: 700, file: AcreBold },
  { fontWeight: 900, file: AcreExtrabold },
]

const arcaMajoraList = [
  { fontWeight: 700, file: ArcaMajoraBold },
  { fontWeight: 900, file: ArcaMajoraHeavy },
]

const acreFonts = acreFontsList.map((font) => ({
  fontFamily: 'Acre',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: font.fontWeight,
  src: `
    url(${font.file})
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}))

const acraMajoraFonts = arcaMajoraList.map((font) => ({
  fontFamily: '"Arca Majora 3"',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: font.fontWeight,
  src: `
    url(${font.file}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}))

const defaultTheme = createTheme()
// Create a theme instance.
const theme = createTheme({
  spacing: 4,
  typography: {
    fontFamily: 'Acre, "Arca Majora 3"',
    h1: {
      fontSize: '64px',
      fontWeight: 700,
      [defaultTheme.breakpoints.down('xs')]: {
        fontSize: '56px',
      },
    },
    h2: {
      fontSize: '32px',
      fontWeight: 500,
      [defaultTheme.breakpoints.down('xs')]: {
        fontSize: '24px',
      },
    },
    h3: {
      fontSize: '24px',
      fontWeight: 700,
      [defaultTheme.breakpoints.down('xs')]: {
        fontSize: '18px',
      },
    },
    h4: {
      fontSize: '24px',
      [defaultTheme.breakpoints.down('xs')]: {
        fontSize: '18px',
      },
    },
    h5: {
      fontSize: '22px',
    },
    h6: {
      fontSize: '18px',
    },
    subtitle1: {
      fontSize: '18px',
      fontWeight: 700,
      fontFamily: '"Arca Majora 3"',
    },
    subtitle2: {
      fontSize: '20px',
      fontWeight: 900,
      fontFamily: '"Arca Majora 3"',
    },
    body1: {
      fontSize: '16px',
      fontFamily: 'Roboto,PingFang,"Helvetica Neue",Helvetica,Arial;',
    },
    body2: {
      fontSize: '14px',
      fontFamily: 'Roboto,PingFang,"Helvetica Neue",Helvetica,Arial;',
    },
    button: {
      textTransform: 'none',
    },
    caption: {
      fontSize: '12px',
      fontFamily: 'Roboto,PingFang,"Helvetica Neue",Helvetica,Arial;',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          label: {
            fontSize: '16px',
            fontWeight: 500,
          },
          containedSecondary: {
            backgroundColor: '#FF5E67',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '@font-face': [...acreFonts, ...acraMajoraFonts],
        },
      },
    },
  },
  palette: {
    background: {
      default: '#fff',
    },
    text: {
      primary: '#3B4346',
      secondary: '#616568',
      disabled: '#A6A8AA',
    },
  },
})

export const getCustomTheme = (brand) => {
  return createTheme({
    spacing: 4,
    typography: {
      fontFamily: brand === 'if' ? 'Acre, "Arca Majora 3"' : 'Roboto',
      h1: {
        fontSize: '64px',
        fontWeight: 700,
        [defaultTheme.breakpoints.down('xs')]: {
          fontSize: '56px',
        },
      },
      h2: {
        fontSize: '32px',
        fontWeight: 500,
        [defaultTheme.breakpoints.down('xs')]: {
          fontSize: '24px',
        },
      },
      h3: {
        fontSize: '24px',
        fontWeight: 700,
        [defaultTheme.breakpoints.down('xs')]: {
          fontSize: '18px',
        },
      },
      h4: {
        fontSize: '24px',
        [defaultTheme.breakpoints.down('xs')]: {
          fontSize: '18px',
        },
      },
      h5: {
        fontSize: '22px',
      },
      h6: {
        fontSize: '18px',
      },
      subtitle1: {
        fontSize: '18px',
        fontWeight: 700,
        fontFamily: '"Arca Majora 3"',
      },
      subtitle2: {
        fontSize: '20px',
        fontWeight: 900,
        fontFamily: '"Arca Majora 3"',
      },
      body1: {
        fontSize: '16px',
        fontFamily: 'Roboto,PingFang,"Helvetica Neue",Helvetica,Arial;',
      },
      body2: {
        fontSize: '14px',
        fontFamily: 'Roboto,PingFang,"Helvetica Neue",Helvetica,Arial;',
      },
      button: {
        textTransform: 'none',
      },
      caption: {
        fontSize: '12px',
        fontFamily: 'Roboto,PingFang,"Helvetica Neue",Helvetica,Arial;',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            label: {
              fontSize: '16px',
              fontWeight: 500,
            },
            containedSecondary: {
              backgroundColor: '#FF5E67',
            },
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          '@global': {
            '@font-face': [...acreFonts, ...acraMajoraFonts],
          },
        },
      },
    },
    palette: {
      background: {
        default: '#fff',
      },
      text: {
        primary: '#3B4346',
        secondary: '#616568',
        disabled: '#A6A8AA',
      },
    },
  })
}

export default theme
