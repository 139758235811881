import { beamColorsV2, darkColorsV2, lightColorsV2, aethirColorsV2, carvColorsV2 } from 'uikit/theme/colorsV2'
export type SaleTheme = {
  saleBoxBackground: string
  saleBoxMobileBackground: string
  saleBoxBorderColor: string

  saleInfoCardBackground: string
  saleInfoCardBorderColor: string
  saleInfoHeadingColor: string
  saleInfoTextColor: string

  estimatedAllocationAreaBackground: string
  estimatedAllocationAmount: string
  estimatedAllocationAmountParticipated: string
  unstakeAreaBackground: string
  unstakeButtonBackground: string

  countdownTimerColor: string
  countdownDescColor: string

  participatedColor1: string
  participatedColor2: string

  manageCardBorderLineMobile: string
}

export const light: SaleTheme = {
  saleBoxBackground: lightColorsV2.feedbacks.disableText,
  saleBoxMobileBackground: lightColorsV2.main,
  saleBoxBorderColor: 'linear-gradient(90deg, #7cafff -2.21%, #c67bff 89.35%)',

  saleInfoCardBackground: lightColorsV2.main,
  saleInfoCardBorderColor: lightColorsV2.light,
  saleInfoHeadingColor: lightColorsV2.text,
  saleInfoTextColor: lightColorsV2.text,

  estimatedAllocationAreaBackground: lightColorsV2.main,
  estimatedAllocationAmount: lightColorsV2.textDisabled,
  estimatedAllocationAmountParticipated: lightColorsV2.text,
  unstakeAreaBackground: lightColorsV2.feedbacks.infoBg,
  unstakeButtonBackground: lightColorsV2.textThirdly,

  countdownTimerColor: lightColorsV2.textLink,
  countdownDescColor: lightColorsV2.textSecondary,

  participatedColor1: '#00CB82',
  participatedColor2: '#00B4ED',

  manageCardBorderLineMobile: 'linear-gradient(90deg, #7cafff -2.21%, #c67bff 89.35%)',
}

export const aethir: SaleTheme = {
  saleBoxBackground: aethirColorsV2.main,
  saleBoxMobileBackground: aethirColorsV2.main,
  saleBoxBorderColor: '#00000080',

  saleInfoCardBackground: aethirColorsV2.main,
  saleInfoCardBorderColor: aethirColorsV2.light,
  saleInfoHeadingColor: aethirColorsV2.text,
  saleInfoTextColor: aethirColorsV2.text,

  estimatedAllocationAreaBackground: aethirColorsV2.main,
  estimatedAllocationAmount: aethirColorsV2.textDisabled,
  estimatedAllocationAmountParticipated: aethirColorsV2.text,
  unstakeAreaBackground: aethirColorsV2.feedbacks.infoBg,
  unstakeButtonBackground: aethirColorsV2.textThirdly,

  countdownTimerColor: aethirColorsV2.textThirdly,
  countdownDescColor: aethirColorsV2.textSecondary,

  participatedColor1: '#000',
  participatedColor2: '#000',

  manageCardBorderLineMobile: '#00000080',
}

export const ronin: SaleTheme = {
  saleBoxBackground: lightColorsV2.feedbacks.disableText,
  saleBoxMobileBackground: lightColorsV2.main,
  saleBoxBorderColor: '#4B7EFF',

  saleInfoCardBackground: lightColorsV2.main,
  saleInfoCardBorderColor: lightColorsV2.light,
  saleInfoHeadingColor: lightColorsV2.text,
  saleInfoTextColor: lightColorsV2.text,

  estimatedAllocationAreaBackground: lightColorsV2.background,
  estimatedAllocationAmount: lightColorsV2.textDisabled,
  estimatedAllocationAmountParticipated: lightColorsV2.text,
  unstakeAreaBackground: lightColorsV2.feedbacks.infoBg,
  unstakeButtonBackground: lightColorsV2.textInfo,

  countdownTimerColor: lightColorsV2.textLink,
  countdownDescColor: lightColorsV2.textSecondary,

  participatedColor1: '#1273EA',
  participatedColor2: '#1C94F4',

  manageCardBorderLineMobile: '#E0E0E0',
}

export const dark: SaleTheme = {
  saleBoxBackground: darkColorsV2.dark2,
  saleBoxMobileBackground: darkColorsV2.dark2,
  saleBoxBorderColor: '#0560FF',

  saleInfoCardBackground: darkColorsV2.dark2,
  saleInfoCardBorderColor: darkColorsV2.light,
  saleInfoHeadingColor: darkColorsV2.text,
  saleInfoTextColor: darkColorsV2.textSecondary,

  estimatedAllocationAreaBackground: darkColorsV2.dark2,
  estimatedAllocationAmount: darkColorsV2.textDisabled,
  estimatedAllocationAmountParticipated: darkColorsV2.textDisabled,
  unstakeAreaBackground: darkColorsV2.main3,
  unstakeButtonBackground: darkColorsV2.textThirdly,

  countdownTimerColor: darkColorsV2.textLink,
  countdownDescColor: darkColorsV2.textDisabled,
  participatedColor1: '#00CB82',
  participatedColor2: '#00B4ED',

  manageCardBorderLineMobile: '#0560FF',
}

export const beam: SaleTheme = {
  saleBoxBackground: beamColorsV2.main2,
  saleBoxMobileBackground: beamColorsV2.main2,
  saleBoxBorderColor: '#0560FF',

  saleInfoCardBackground: beamColorsV2.main2,
  saleInfoCardBorderColor: beamColorsV2.dark2,
  saleInfoHeadingColor: beamColorsV2.text,
  saleInfoTextColor: beamColorsV2.text,

  estimatedAllocationAreaBackground: beamColorsV2.main2,
  estimatedAllocationAmount: beamColorsV2.textDisabled,
  estimatedAllocationAmountParticipated: beamColorsV2.text,
  unstakeAreaBackground: beamColorsV2.feedbacks.infoBg,
  unstakeButtonBackground: beamColorsV2.textThirdly,

  countdownTimerColor: beamColorsV2.textLink,
  countdownDescColor: beamColorsV2.textSecondary,
  participatedColor1: '#434D76',
  participatedColor2: '#5B629E',

  manageCardBorderLineMobile: '#0560FF',
}

export const carv: SaleTheme = {
  saleBoxBackground: carvColorsV2.main2,
  saleBoxMobileBackground: carvColorsV2.main2,
  saleBoxBorderColor: '#8a5aff80',

  saleInfoCardBackground: carvColorsV2.main2,
  saleInfoCardBorderColor: carvColorsV2.dark2,
  saleInfoHeadingColor: carvColorsV2.text,
  saleInfoTextColor: carvColorsV2.text,

  estimatedAllocationAreaBackground: carvColorsV2.main2,
  estimatedAllocationAmount: carvColorsV2.textDisabled,
  estimatedAllocationAmountParticipated: carvColorsV2.text,
  unstakeAreaBackground: carvColorsV2.feedbacks.infoBg,
  unstakeButtonBackground: carvColorsV2.textThirdly,

  countdownTimerColor: carvColorsV2.textLink,
  countdownDescColor: carvColorsV2.textSecondary,
  participatedColor1: '#8A5AFF',
  participatedColor2: '#5B629E',

  manageCardBorderLineMobile: '#0560FF',
}
