import { Box, CircularProgress } from '@mui/material'

import IFTypography from 'componentsV2/IFTypography'
import { useTranslation } from 'contexts/Localization'
import React, { useCallback, useState } from 'react'
import { LinkExternal, Button, Flex, VerifiedIcon, useModalV2 } from 'uikit'
import styled, { useTheme } from 'styled-components'

import useSignOAuth from 'hooks/useSignOAuth'
import { useKycSession } from 'hooks/useKycSession'
import { SubmitKYCBody, SubmitKYCSourceBody } from 'state/v2_types'
import { useVerifyPopup } from 'authento-react'
import { ZkMeWidget, type Provider } from '@zkmelabs/widget'

import { useIsUserSignedIn } from 'state/user/hooks'
import { CompliancePage } from './ComplianceCard'
import useKycStatus from 'hooks/useKycStatus'
import { KYCStatus } from 'state/types'
import { useAccount, useNetwork } from 'wagmi'
import useWeb3 from 'hooks/useWeb3'

const StyledButton = styled(Button)`
  width: 100%;
  height: auto;
  align-self: center;
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2.light : theme.colorsV2.dark)};
`

interface ComplianceModalProps {
  submitKYC: (body: SubmitKYCBody, noToast?: boolean, onSucceed?: any, onError?: any) => void
  submitKYCSource: (body: SubmitKYCSourceBody) => void
  isLoading: boolean
  recompliance?: boolean
  setPage: (page: CompliancePage) => void
}

const KYCList: React.FC<ComplianceModalProps> = ({ submitKYCSource, isLoading, recompliance, setPage }) => {
  const { signOAuth } = useSignOAuth('if')
  const { address } = useAccount()
  const { status, kycSource } = useKycStatus()
  const [_, onDismiss] = useModalV2({
    modal: <Box />,
  })

  const [isSubmitBABTLoading, setIsSubmitBABTLoading] = useState(false)
  const isUserSigned = useIsUserSignedIn()
  const theme = useTheme()
  const { chain } = useNetwork()
  const chainId = chain?.id

  const { verifyBasic } = useVerifyPopup({
    domainName: 'Impossible Finance',
    authentoUrl: process.env.REACT_APP_STAGING ? 'https://demo-app.authento.io' : 'https://app.authento.io',
    messageText:
      'Sign this message to prove your ownership of this address and ' +
      'proceed with the identity verification process.',
  })

  const web3 = useWeb3()

  const { onSession, isLoading: isKycSessionLoading } = useKycSession()
  const isValid = true

  const handleSubmitWithAuthento = useCallback(() => {
    if (!isUserSigned) {
      signOAuth(() => {
        submitKYCSource({ source: 'AUTHENTO' })
        verifyBasic()
      })
    } else {
      submitKYCSource({ source: 'AUTHENTO' })
      verifyBasic()
    }

    // no toast for kyc, compliance is updated in the background
  }, [submitKYCSource, onSession, verifyBasic])

  const handleSubmitWithSynapse = useCallback(() => {
    if (!isUserSigned) {
      signOAuth(() => {
        submitKYCSource({ source: 'SYNAPS' })
        onSession()
      })
    } else {
      submitKYCSource({ source: 'SYNAPS' })
      onSession()
    }
    // no toast for kyc, compliance is updated in the background
  }, [submitKYCSource, onSession])

  const handleSubmitWithBab = useCallback(() => {
    setIsSubmitBABTLoading(true)
    // no toast for kyc, compliance is updated in the background

    if (!isUserSigned) {
      signOAuth(() => {
        submitKYCSource({ source: 'BABT' })
        setPage('bab')
        setIsSubmitBABTLoading(false)
      })
    } else {
      submitKYCSource({ source: 'BABT' })
      setPage('bab')
      setIsSubmitBABTLoading(false)
    }
  }, [submitKYCSource])

  const { t } = useTranslation()

  const rejectedSource = status === KYCStatus.REJECTED ? kycSource : ''

  const handleSubmitWithZkMe = useCallback(() => {
    onDismiss()
    // no toast for kyc, compliance is updated in the background
    const provider: Provider = {
      async getAccessToken() {
        // Request a new token from your backend service and return it to the widget
        const body = {}

        const URL = process.env.REACT_APP_BACKEND_URL
        const ENDPOINT = `${URL}/api/backend-service/zkme/init?lv=1`

        const response = await fetch(ENDPOINT, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify(body),
        })
        const responsedata = await response.json()
        return responsedata.data
      },
      async getUserAccounts() {
        return [address]
      },

      // The following methods implement one of these
      // depending on the type of blockchain your project is running on.

      // EVM
      async delegateTransaction(tx) {
        const txResponse = await web3.eth.sendTransaction(tx)
        return txResponse.hash
      },
    }

    const zkMeWidget = new ZkMeWidget('M2024010318556793225516931836653', 'IF', chainId.toString(), provider)

    if (!isUserSigned) {
      signOAuth(() => {
        submitKYCSource({ source: 'ZKME' })
        zkMeWidget.launch()
      })
    } else {
      submitKYCSource({ source: 'ZKME' })
      zkMeWidget.launch()
    }
  }, [submitKYCSource, address, web3])

  return (
    <>
      <Flex margin="0px auto 40px">
        <VerifiedIcon height="85px" width="85px" color="#b0d3f9" />
      </Flex>
      <IFTypography fontSize="14px" fontWeight="bold" lineHeight="20px" align="center" paddingBottom="30px">
        {t('Choose either route to complete your KYC.')}
      </IFTypography>

      <>
        {!(rejectedSource?.length > 0 && rejectedSource === 'ZKME') &&
          process.env.REACT_APP_WHITELABEL_BRAND !== 'ronin' && (
            <StyledButton
              style={{ padding: '16px' }}
              onClick={handleSubmitWithZkMe}
              margin="5px"
              isLoading={isKycSessionLoading}
            >
              <img
                style={{ height: '28px' }}
                height="100%"
                src={`/images/icons/kyc/${theme.isDark ? 'zkme_dark.svg' : 'zkme_light.svg'}`}
              />
            </StyledButton>
          )}
        {!(rejectedSource?.length > 0 && rejectedSource === 'SYNAPS') && (
          <StyledButton
            style={{ padding: '20px' }}
            onClick={handleSubmitWithSynapse}
            margin="5px"
            isLoading={isKycSessionLoading}
          >
            {isKycSessionLoading && (
              <Flex paddingRight="10px">
                <CircularProgress color="inherit" size="16px" />{' '}
              </Flex>
            )}
            <img height="100%" src={`/images/icons/kyc/${theme.isDark ? 'synaps_dark.svg' : 'synaps_light.svg'}`} />
          </StyledButton>
        )}
        <StyledButton
          style={{ padding: '7px 20px' }}
          onClick={handleSubmitWithBab}
          margin="5px"
          isLoading={isSubmitBABTLoading}
        >
          {isSubmitBABTLoading && (
            <Flex paddingRight="10px">
              <CircularProgress color="inherit" size="16px" />{' '}
            </Flex>
          )}
          <img height="44px" src={`/images/icons/kyc/${theme.isDark ? 'babt_dark.png' : 'babt_light.png'}`} />
        </StyledButton>
        <Flex alignItems="center" alignSelf="center" marginTop={4}>
          <LinkExternal
            small
            href="https://impossible.gitbook.io/impossible-finance-faq/start-here/get-started/verify-kyc-your-wallet"
            margin="5px 0px 30px 0px"
          >
            {t('How to verify KYC')}
          </LinkExternal>
        </Flex>{' '}
      </>
    </>
  )
}

export default KYCList
