// Step 6 Add more Chains

import { BASE_BSC_SCAN_URLS } from 'config'
import { Chain } from 'config/constants/types'
import { nodes } from 'utils/getRpcUrl'

const networkParams = {
  [Chain.BSC_MAINNET]: [
    {
      chainId: `0x${Chain.BSC_MAINNET.toString(16)}`,
      chainName: 'BSC Mainnet',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.BSC_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.BSC_MAINNET]],
    },
  ],
  [Chain.BSC_TESTNET]: [
    {
      chainId: `0x${Chain.BSC_TESTNET.toString(16)}`,
      chainName: 'BSC Testnet',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.BSC_TESTNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.BSC_TESTNET]],
    },
  ],
  [Chain.POLYGON_MAINNET]: [
    {
      chainId: `0x${Chain.POLYGON_MAINNET.toString(16)}`,
      chainName: 'Polygon',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.POLYGON_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.POLYGON_MAINNET]],
    },
  ],
  [Chain.MOONRIVER_MAINNET]: [
    {
      chainId: `0x${Chain.MOONRIVER_MAINNET.toString(16)}`,
      chainName: 'Moonriver',
      nativeCurrency: {
        name: 'MOVR',
        symbol: 'MOVR',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.MOONRIVER_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.MOONRIVER_MAINNET]],
    },
  ],
  [Chain.AVAX_MAINNET]: [
    {
      chainId: `0x${Chain.AVAX_MAINNET.toString(16)}`,
      chainName: 'Avalanche',
      nativeCurrency: {
        name: 'AVAX',
        symbol: 'AVAX',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.AVAX_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.AVAX_MAINNET]],
    },
  ],
  [Chain.ETH_MAINNET]: [
    {
      chainId: `0x${Chain.ETH_MAINNET.toString(16)}`,
      chainName: 'Ethereum',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.ETH_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.ETH_MAINNET]],
    },
  ],
  [Chain.MOONBEAM_MAINNET]: [
    {
      chainId: `0x${Chain.MOONBEAM_MAINNET.toString(16)}`,
      chainName: 'Moonbeam',
      nativeCurrency: {
        name: 'GLMR',
        symbol: 'GLMR',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.MOONBEAM_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.MOONBEAM_MAINNET]],
    },
  ],
  [Chain.AURORA_MAINNET]: [
    {
      chainId: `0x${Chain.AURORA_MAINNET.toString(16)}`,
      chainName: 'Aurora',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.AURORA_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.AURORA_MAINNET]],
    },
  ],

  [Chain.GOERLI]: [
    {
      chainId: `0x${Chain.GOERLI.toString(16)}`,
      chainName: 'Goerli',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.GOERLI],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.GOERLI]],
    },
  ],

  [Chain.ARBITRUM_MAINNET]: [
    {
      chainId: `0x${Chain.ARBITRUM_MAINNET.toString(16)}`,
      chainName: 'Arbitrum Mainnet',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.ARBITRUM_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.ARBITRUM_MAINNET]],
    },
  ],
  [Chain.ARBITRUM_SEPOLIA]: [
    {
      chainId: `0x${Chain.ARBITRUM_SEPOLIA.toString(16)}`,
      chainName: 'Arbitrum Sepolia',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.ARBITRUM_SEPOLIA],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.ARBITRUM_SEPOLIA]],
    },
  ],

  [Chain.SAIGON]: [
    {
      chainId: `0x${Chain.SAIGON.toString(16)}`,
      chainName: 'Saigon Testnet',
      nativeCurrency: {
        name: 'RON',
        symbol: 'RON',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.SAIGON],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.SAIGON]],
    },
  ],

  [Chain.RONIN]: [
    {
      chainId: `0x${Chain.RONIN.toString(16)}`,
      chainName: 'RONIN',
      nativeCurrency: {
        name: 'RON',
        symbol: 'RON',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.RONIN],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.RONIN]],
    },
  ],

  [Chain.ARBITRUM_TESTNET]: [
    {
      chainId: `0x${Chain.ARBITRUM_TESTNET.toString(16)}`,
      chainName: 'Arbitrum Testnet',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.ARBITRUM_TESTNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.ARBITRUM_TESTNET]],
    },
  ],

  [Chain.OPTIMISM_MAINNET]: [
    {
      chainId: `0x${Chain.OPTIMISM_MAINNET.toString(16)}`,
      chainName: 'Optimism Mainnet',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.OPTIMISM_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.OPTIMISM_MAINNET]],
    },
  ],

  [Chain.OPTIMISM_TESTNET]: [
    {
      chainId: `0x${Chain.OPTIMISM_TESTNET.toString(16)}`,
      chainName: 'Optimism Testnet',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.OPTIMISM_TESTNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.OPTIMISM_TESTNET]],
    },
  ],

  [Chain.NEON_DEVNET]: [
    {
      chainId: `0x${Chain.NEON_DEVNET.toString(16)}`,
      chainName: 'Neon Devnet',
      nativeCurrency: {
        name: 'NEON',
        symbol: 'NEON',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.NEON_DEVNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.NEON_DEVNET]],
    },
  ],
  [Chain.OKC_MAINNET]: [
    {
      chainId: `0x${Chain.OKC_MAINNET.toString(16)}`,
      chainName: 'OKC Mainnet',
      nativeCurrency: {
        name: 'OKT',
        symbol: 'OKT',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.OKC_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.OKC_MAINNET]],
    },
  ],
  [Chain.OMNI_TESTNET]: [
    {
      chainId: `0x${Chain.OMNI_TESTNET.toString(16)}`,
      chainName: 'OMNI Testnet',
      nativeCurrency: {
        name: 'OMNI',
        symbol: 'OMNI',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.OMNI_TESTNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.OMNI_TESTNET]],
    },
  ],
  [Chain.ZKSYNC_ERA_MAINNET]: [
    {
      chainId: `0x${Chain.ZKSYNC_ERA_MAINNET.toString(16)}`,
      chainName: 'ZkSync Era Mainnet',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.ZKSYNC_ERA_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.ZKSYNC_ERA_MAINNET]],
    },
  ],
  [Chain.ZKSYNC_SEPOLIA]: [
    {
      chainId: `0x${Chain.ZKSYNC_SEPOLIA.toString(16)}`,
      chainName: 'ZkSync Sepolia Mainnet',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.ZKSYNC_SEPOLIA],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.ZKSYNC_SEPOLIA]],
    },
  ],
  [Chain.HUMANODE_TESTNET]: [
    {
      chainId: `0x${Chain.HUMANODE_TESTNET.toString(16)}`,
      chainName: 'HUMANODE Testnet',
      nativeCurrency: {
        name: 'eHMND',
        symbol: 'eHMND',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.HUMANODE_TESTNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.HUMANODE_TESTNET]],
    },
  ],
  [Chain.HUMANODE_MAINNET]: [
    {
      chainId: `0x${Chain.HUMANODE_MAINNET.toString(16)}`,
      chainName: 'HUMANODE Mainnet',
      nativeCurrency: {
        name: 'eHMND',
        symbol: 'eHMND',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.HUMANODE_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.HUMANODE_MAINNET]],
    },
  ],
  [Chain.LINEA_TESTNET]: [
    {
      chainId: `0x${Chain.LINEA_TESTNET.toString(16)}`,
      chainName: 'Linea Testnet',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.LINEA_TESTNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.LINEA_TESTNET]],
    },
  ],
  [Chain.ARBITRUM_GOERLI]: [
    {
      chainId: `0x${Chain.ARBITRUM_GOERLI.toString(16)}`,
      chainName: 'Arbitrum Goerli Testnet',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.ARBITRUM_GOERLI],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.ARBITRUM_GOERLI]],
    },
  ],
  [Chain.MANTA_MAINNET]: [
    {
      chainId: `0x${Chain.MANTA_MAINNET.toString(16)}`,
      chainName: 'Manta Mainnet',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.MANTA_MAINNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.MANTA_MAINNET]],
    },
  ],
  [Chain.OKX_TESTNET]: [
    {
      chainId: `0x${Chain.OKX_TESTNET.toString(16)}`,
      chainName: 'OKX_TESTNET',
      nativeCurrency: {
        name: 'OKB',
        symbol: 'OKB',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.OKX_TESTNET],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.OKX_TESTNET]],
    },
  ],
  [Chain.BASE_SEPOLIA]: [
    {
      chainId: `0x${Chain.BASE_SEPOLIA.toString(16)}`,
      chainName: 'Base Sepolia',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.BASE_SEPOLIA],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.BASE_SEPOLIA]],
    },
  ],
  [Chain.BEAM]: [
    {
      chainId: `0x${Chain.BEAM.toString(16)}`,
      chainName: 'BEAM',
      nativeCurrency: {
        name: 'BEAM',
        symbol: 'BEAM',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.BEAM],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.BEAM]],
    },
  ],
  [Chain.BEAM_TEST]: [
    {
      chainId: `0x${Chain.BEAM_TEST.toString(16)}`,
      chainName: 'BEAM ',
      nativeCurrency: {
        name: 'BEAM',
        symbol: 'BEAM',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.BEAM_TEST],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.BEAM_TEST]],
    },
  ],
  [Chain.IMMUTABLE]: [
    {
      chainId: `0x${Chain.IMMUTABLE.toString(16)}`,
      chainName: 'IMMUTABLE',
      nativeCurrency: {
        name: 'IMX',
        symbol: 'IMX',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.IMMUTABLE],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.IMMUTABLE]],
    },
  ],
  [Chain.IMMUTABLE_TEST]: [
    {
      chainId: `0x${Chain.IMMUTABLE_TEST.toString(16)}`,
      chainName: 'IMMUTABLE TEST',
      nativeCurrency: {
        name: 'tIMX',
        symbol: 'tIMX',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.IMMUTABLE_TEST],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.IMMUTABLE_TEST]],
    },
  ],
  [Chain.MANTLE]: [
    {
      chainId: `0x${Chain.MANTLE.toString(16)}`,
      chainName: 'MANTLE',
      nativeCurrency: {
        name: 'MNT',
        symbol: 'MNT',
        decimals: 18,
      },
      rpcUrls: nodes[Chain.MANTLE],
      blockExplorerUrls: [BASE_BSC_SCAN_URLS[Chain.MANTLE]],
    },
  ],
}

export default networkParams
