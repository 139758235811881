import { useMemo } from 'react'
import useWeb3, { walletClientToSigner } from 'hooks/useWeb3'
import {
  getBep20Contract,
  getMasterchefContract,
  getIFASaleContract,
  getIFAMasterContract,
  getSolvMarketContract,
  getSolvVoucherContract,
  getVIDIAContract,
  getMasterchefContractWithAddress,
  getLPContract,
  getQuestRewardContract,
  getIFFixedSaleContract,
} from 'utils/contractHelpers'
import { getENSRegistrarAddress, getMulticallAddress, getRouterAddress, getWGasAddress } from 'utils/addressHelpers'
import { Contract } from '@ethersproject/contracts'
import { useAccount, useNetwork, useWalletClient } from 'wagmi'

import { Chain } from 'config/constants/types'
import { getContract } from 'utils'
import multiCallAbi from 'config/abi/Multicall.json'
import IUniswapV2Router02ABI from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import WETH_ABI from 'config/abi/weth.json'
import IImpossiblePairABI from 'config/abi/IImpossiblePair.json'
import { ERC20_BYTES32_ABI } from 'config/abi/erc20'
import ENS_PUBLIC_RESOLVER_ABI from 'config/abi/ens-public-resolver.json'
import ENS_ABI from 'config/abi/ens-registrar.json'
import ERC20_ABI from 'config/abi/erc20.json'
import { getProviderNoAccount } from 'utils/web3'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = (address: string) => {
  const web3 = useWeb3()
  return useMemo(() => getBep20Contract(address, web3), [address, web3])
}

export const useMasterchef = () => {
  const web3 = useWeb3()
  return useMemo(() => getMasterchefContract(web3), [web3])
}

export const useMasterchefContract = (address: string) => {
  const web3 = useWeb3()
  return useMemo(() => getMasterchefContractWithAddress(address, web3), [address, web3])
}

export const useLPContract = (address: string) => {
  const web3 = useWeb3()
  return useMemo(() => getLPContract(address, web3), [address, web3])
}

export const useIFAMasterContract = (address) => {
  const web3 = useWeb3()
  return useMemo(() => getIFAMasterContract(address, web3), [address, web3])
}

export const useQuestRewardContract = (address) => {
  const web3 = useWeb3()
  return useMemo(() => getQuestRewardContract(address, web3), [address, web3])
}

export const useIFASaleContract = (address) => {
  const web3 = useWeb3()
  return useMemo(() => getIFASaleContract(address, web3), [address, web3])
}

export const useIFFixedSaleContract = (address) => {
  const web3 = useWeb3()
  return useMemo(() => getIFFixedSaleContract(address, web3), [address, web3])
}

export const useSolvVoucherContract = (address) => {
  const web3 = useWeb3()
  return useMemo(() => getSolvVoucherContract(address, web3), [address, web3])
}

export const useSolvMarketContract = (address) => {
  const web3 = useWeb3()
  return useMemo(() => getSolvMarketContract(address, web3), [address, web3])
}

export const useVIDIAContract = (address) => {
  const web3 = useWeb3()
  return useMemo(() => getVIDIAContract(address, web3), [address, web3])
}

// Migrated from SWAP
// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { chain } = useNetwork()
  const { address: account } = useAccount()

  const chainId = chain?.id || 0

  const { data: walletClient } = useWalletClient({ chainId: chainId })

  const client =
    // @ts-ignore
    walletClient
      ? // @ts-ignore
        walletClientToSigner(walletClient).provider.provider
      : getProviderNoAccount(chainId)

  return useMemo(() => {
    if (!address || !ABI) return null
    try {
      return getContract(address, ABI, client, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, client, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWGasContract(): Contract | null {
  const { chain } = useNetwork()
  const chainId = chain?.id || 0
  return useContract(getWGasAddress(chainId), WETH_ABI, true)
}

export function useBytes32TokenContract(tokenAddress?: string): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, false)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IImpossiblePairABI.abi, false)
}

export function useMulticallContract(chainId: Chain): Contract | null {
  return useContract(getMulticallAddress(chainId), multiCallAbi, false)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  // const web3 = useWeb3()
  const { chain } = useNetwork()
  const chainId = chain?.id || 0
  const contract = useContract(getENSRegistrarAddress(chainId), ENS_ABI, withSignerIfPossible)
  if (!contract) {
    return null
  }
  return contract
}

export function useENSResolverContract(address: string): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, false)
}

export function useRouterContract(chainId: Chain): Contract | null {
  return useContract(getRouterAddress(chainId), IUniswapV2Router02ABI.abi, true)
}
